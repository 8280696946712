import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout';
import { Link } from 'react-router-dom'
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import QRCode from 'react-qr-code';


const Home = ({socket}) => {
    const [qrData,setQrData] = useState("undefined")
    const [socketId,setSocketId] = useState("")
    socket.on('user-joined',(msg)=>{
        console.log(msg,'msg');
        setQrData(msg)
        const data = JSON.parse(atob(msg))
        setSocketId(data.socket)
    })

    socket.on('receive',(msg)=>{
        window.location.href = msg.url
    })

    const redirectPage = () =>{
        const obj = {url:"https://www.w3schools.com/",socket:socketId}
        const enc = btoa(JSON.stringify(obj))
        console.log(enc,"enc");
        socket.emit('send',enc)
    }

    useEffect(()=>{
        socket.emit('new-user-joined', {
            text: "Tushar",
            socketID: socket.id,
          });
    },[])
    return (
        <>
            <Layout>
                <section className='rs-hero'>
                    <Container>
                        <div className='rs-hero-box'>
                            <Row className='align-items-center g-sm-4 g-3'>
                                <Col lg={6} className='order-lg-1 order-2'>
                                    <div className='rs-hero-media'>
                                        <img src='/img/hero.svg' alt='Scan QR Code' className='img-fluid' />
                                    </div>
                                </Col>
                                <Col lg={6} className='order-lg-2 order-1'>
                                    <div className='rs-hero-scanner'>
                                        <div className='rs-hero-content'>
                                            <h1>Scan QR Code</h1>
                                            <p>Use Rapidshare on your mobile phone to scan the QR Code</p>
                                        </div>
                                        <div className='rs-hero-qrcode'>
                                            <QRCode size={200} value={qrData} viewBox="0 0 256 256" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='g-sm-4 g-3 mt-lg-5 mt-md-4 mt-3 justify-content-center'>
                                <Col xs={12}>
                                    <h2 className='rs-title center'>How Rapidshare Works?</h2>
                                </Col>
                                <Col xl={4} md={6}>
                                    <div className='rs-how-work step-1'>
                                        <h6>01</h6>
                                        <p>Connect your mobile and PC to the same Wi-Fi network. Launch RapidShare on your phone and scan the QR code to link both devices.</p>
                                    </div>
                                </Col>
                                <Col xl={4} md={6}>
                                    <div className='rs-how-work step-2'>
                                        <h6>02</h6>
                                        <p>Use RapidShare's easy interface to transfer documents, photos, or videos between your mobile and PC, even without the internet.</p>
                                    </div>
                                </Col>
                                <Col xl={4} md={6}>
                                    <div className='rs-how-work step-3'>
                                        <h6>03</h6>
                                        <p>Experience rapid file transfers with RapidShare, making it effortless to share content between your devices anytime, anywhere.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <img src='/img/hero-shape-down.svg' alt='Scan QR Code' className='img-fluid hero-shape hero-shape-down' />
                    <img src='/img/hero-shape-up.svg' alt='Scan QR Code' className='img-fluid hero-shape hero-shape-up' />
                </section>

                <section className='rs-about pt-50'>
                    <Container>
                        <Row className='g-sm-4 g-3'>
                            <Col xs={12}>
                                <h2 className='rs-title'>About Rapidshare</h2>
                            </Col>
                            <Col xs={12}> 
                                <p>RapidShare is an efficient app for fast and reliable file transfers between mobile to mobile and mobile to PC/Laptop. RapidShare ensures seamless communication by connecting 
                                your smartphone and other devices (such as computers, phones, or tablets) to the same Wi-Fi network.</p>
                                <p className='mb-0'>Open the RapidShare app on your Android or iOS smartphone to transfer files. Use the app's QR code scanning feature to establish a direct connection with the target device.
                                This eliminates the need for complex setup procedures, enabling fast data exchange.</p>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='rs-benefits pt-50'>
                    <Container>
                        <Row className='g-sm-4 g-3'>
                            <Col xs={12}>
                                <h2 className='rs-title'>Benefits Rapidshare</h2>
                            </Col>
                            <Col sm={6}>
                                <div className='rs-benefits-card'>
                                    <div className='rs-benefits-media'>
                                        <span>01</span>
                                        <img src='/img/seamless-connectivity.svg' alt='Seamless Connectivity' className='img-fluid' />
                                    </div>
                                    <h3>Seamless Connectivity</h3>
                                    <p>Easily transfer files between your mobile, PC, or laptop without any hassle. Experience smooth and quick file exchanges across multiple devices.</p>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className='rs-benefits-card'>
                                    <div className='rs-benefits-media'>
                                        <span>02</span>
                                        <img src='/img/offline-sharing.svg' alt='Offline Sharing' className='img-fluid' />
                                    </div>
                                    <h3>Offline Sharing</h3>
                                    <p>No Internet is required. Internet is needed just once to open a URL for mobile-to-PC; mobile-to-mobile sharing doesn't require Internet.</p>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className='rs-benefits-card'>
                                    <div className='rs-benefits-media'>
                                        <span>03</span>
                                        <img src='/img/user-friendly-interface.svg' alt='User-Friendly Interface' className='img-fluid' />
                                    </div>
                                    <h3>User-Friendly Interface</h3>
                                    <p>The app is designed for simplicity, ensuring anyone can use it without confusion. Its intuitive layout makes navigation straightforward for all users.</p>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className='rs-benefits-card'>
                                    <div className='rs-benefits-media'>
                                        <span>04</span>
                                        <img src='/img/effortless-transfer-of-large-files.svg' alt='Effortless Transfer of Large Files' className='img-fluid' />
                                    </div>
                                    <h3>Effortless Transfer of Large Files</h3>
                                    <p>Send even large files quickly and efficiently between devices. The app handles high-volume data transfers with ease, saving you time.</p>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className='rs-benefits-card'>
                                    <div className='rs-benefits-media'>
                                        <span>05</span>
                                        <img src='/img/convenience.svg' alt='Convenience' className='img-fluid' />
                                    </div>
                                    <h3>Convenience</h3>
                                    <p>Save time and effort by streamlining the file transfer process, whether it's documents, photos, or videos. Enjoy a more efficient way to manage your files.</p>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className='rs-benefits-card'>
                                    <div className='rs-benefits-media'>
                                        <span>06</span>
                                        <img src='/img/enhanced-security.svg' alt='Enhanced Security' className='img-fluid' />
                                    </div>
                                    <h3>Enhanced Security</h3>
                                    <p>Ensure your files are transferred securely with robust encryption protocols. Protect your data from unauthorized access during the transfer process.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='rs-faqs pt-50 pb-50'>
                    <Container>
                        <Row className='g-sm-4 g-3'>
                            <Col xs={12}>
                                <h2 className='rs-title'>Frequently Asked Questions</h2>
                            </Col>
                            <Col xs={12}>
                                <Accordion defaultActiveKey="0" flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Q. How do you share files between mobile & PC/Laptop?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Connect both devices to the same Wi-Fi network, open the RapidShare app on your smartphone, scan the QR code shown on your PC/Laptop, and you're ready to start sharing files.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Q. What is RapidShare: Fast File Transfer?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>It's a mobile app for quickly transferring files between your smartphone and PC/Laptop.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Q. Can I share files offline?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Yes, RapidShare allows you to share files without an internet connection.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Q. Can I send large files?</Accordion.Header>
                                        <Accordion.Body><p>Yes, RapidShare enables effortless transfer of large files between devices.</p></Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <footer className='rs-footer pt-50'>
                    <Container>
                        <Row className='align-items-center g-sm-4 g-3'>
                            <Col lg={8} md={6}>
                                <div className='rs-footer-content'>
                                    <img src='/logo/logo.svg' className='img-fluid' alt='RapidShare'/>
                                    <p>Transfer files easily with Rapid Share. Quickly send documents, photos,<br/> and videos between mobile devices and PC/laptops.</p>
                                    <ul>
                                        <li><a href='/privacy-policy' target='_blank'>Privacy Policy</a></li>
                                        <li><a href='/terms-of-use' target='_blank'>Terms and Condition</a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className='rs-app-btn'>
                                    <Link to="https://play.google.com/store/apps/details?id=com.vasu.rapidshare.filetransfer" target='_blank'><img src='/img/play-store.svg' alt='PlayStore' className='img-fluid'/></Link>
                                    <Link to="https://apps.apple.com/us/app/id6503691134" target='_blank'><img src='/img/app-store.svg' alt='AppStore' className='img-fluid'/></Link>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <p className='rs-copyright'>All rights reserved ©2024 <span>Rapidshare.</span></p>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </Layout>
        </>
    )
}

export default Home